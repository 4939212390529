import { Box, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import React from 'react';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useWallet } from '../../../contexts/WalletContext';
import { ButtonContent, ButtonOutline, ColorButton } from '../../../styles/component/button';
import { TitleBold } from '../../../styles/component/typography';



const Signature = () => {
    const { modalIsOpen, SignAccount, click, setClick } = useAuthContext();
    const {disconnect} = useWallet();
    function onDisconnect() {
        disconnect();
        window.location.href = "/connect-wallet";
    }
    function onSignin(){
        SignAccount();
        setClick(true)
    }

    return (
        <Backdrop sx={{
            zIndex: 10000,
            color: '#fff',
            backgroundColor: "#222"
        }} open={modalIsOpen}>
            <Box textAlign={"center"} sx={{
                maxWidth: 350,
                textAlign: "center",
                fontWeight: "600",
                fontSize: 18,
                marginBottom: 32,
                borderRadius: 10,
            }}>
                <TitleBold>
                    Looks like you've changed primary address in your wallet. You should sign new authentication message
                </TitleBold>
                <Stack direction={"row"} justifyContent="center" spacing={1} mt={1}>
                    {!click && (
                        <Box sx={{width:'50%'}}>
                            <ButtonOutline sx={{width:'100%',borderRadius:'10px'}} onClick={onSignin}>
                                <ButtonContent sx={{width:'100%',background:'#222'}}>Sign message</ButtonContent>
                            </ButtonOutline> 
                        </Box>
                    )}
                    <Box sx={{width:'50%'}}>
                        <ColorButton sx={{width: "100%",height:'100%',borderRadius: "10px"}} onClick={onDisconnect}>
                            Disconnect
                        </ColorButton>
                    </Box>
                </Stack>
            </Box>
           
        </Backdrop>
    );
};

export default Signature;