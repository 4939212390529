import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import collectionApi from "../api/collectionApi";
import { useWallet } from "./WalletContext";

const CoinPriceContext = createContext({});

export const CoinPriceProvider = ({ children }) => {
    const {network} = useWallet()
    const [coins, setCoins] = useState({
        BNB: {},
        WETH: {},
        ETH: {},
        EBZ: {},
        MATIC: {}
    });
    const [fixedAllowCurrency, setFixedAllow] = useState([])
    const [auctionAllowurrency, setAuctionAllow] = useState([])

  const fetchTokenAllow = async () => {
        try {
            let response = await collectionApi.getToken(`?network=${network}`)
            if(response) {
                response.tokens.forEach(token => {
                    if(token.type === "TOKEN") {
                        setAuctionAllow(previewData => [...previewData, {_id: token.symbol, name: token.symbol}])
                    }
                    setFixedAllow(previewData => [...previewData, {_id: token.symbol, name: token.symbol}])
                })

            }
        } catch (error) {
            return false
        }
  }
  const fetchCoinPriceData = async () => {
        const {
            data: [wbnb, weth, wmatic],
        } = await axios.get("https://api.coingecko.com/api/v3/coins/markets", {
            params: {
                vs_currency: "usd",
                ids: `wbnb,weth,wmatic`,
                sparkline: false,
            },
        });
        setCoins({
            WETH: {
                price: weth.current_price,
                image: weth.image,
            },
            BNB: {
                price: wbnb.current_price,
                image: wbnb.image,
            },
            ETH: {
                price: weth.current_price,
                image: weth.image,
            },
            EBZ: {
                price: wmatic.current_price,
                image: wmatic.image,
            },
            MATIC: {
                price: wmatic.current_price,
                image: wmatic.image,
            }
        });
  };

  useEffect(() => {
      fetchCoinPriceData();
  }, []);
  useEffect(() => {
    if(network) {
        fetchTokenAllow()
    }
    
  }, [network])

  return (
    <CoinPriceContext.Provider value={{ coins, fixedAllowCurrency, auctionAllowurrency }}>
      {children}
    </CoinPriceContext.Provider>
  );
};

export const useCoinPrice = () => useContext(CoinPriceContext);
