import { BigNumber, ethers } from "ethers";
import { getCollectionContract, getContractInfo, getContractObj, getDomainSign, offerTypeBuy, tokenType, zeroAddr } from ".";

export function isAddress(address) {
    try {
        ethers.utils.getAddress(address);
    } catch (e) { return false; }
    return true;
}

export function toEth(amount,decimal) {
    return ethers.utils.formatUnits(String(amount),decimal) 
}

export function toWei(amount,decimal) {    
  return ethers.utils.parseUnits(String(amount),decimal);
}

export async function createCollection(type, data, chainId, provider) {
    let typeCollection = type === "single" ? "ERC721Factory" : "ERC1155Factory";
    const contract = getContractObj(typeCollection, chainId, provider);
    console.log(contract)
    if (!contract) return false
    const {name, symbol, uri, isPublic} = data;
    try {
        if(type === "single") {
            const tx =  await contract.createERC721Collection(name, symbol, uri, isPublic);
            const receipt = await tx.wait(2);
            console.log({receipt})
            return receipt
        }
        if(type === "multi") {
            const tx =  await contract.createERC1155Collection(name, symbol, uri, isPublic);
            const receipt = await tx.wait(2);
            return receipt
        } 
        return false;
        
    }catch(e) {
        console.log(e)
        return false;
    }        
}

export async function mintERC721(voucher, chainId, provider) {
    const contract = getContractObj("MARKET", chainId, provider);
    
    const domainSign = getDomainSign(chainId, contract.address);
    if (!contract) return false
    try {
        const signature = await provider._signTypedData(domainSign, tokenType, voucher);
        return {
            ...voucher,
            signature,
        };
    }catch(e) {
        console.log(e)
        return false;
    }        
}

export async function createSale(voucher, chainId, provider) {
    const contract = getContractObj("MARKET", chainId, provider);
    const domainSign = getDomainSign(chainId, contract.address);
    console.log({domainSign, voucher, provider})
    if (!contract) return false
    voucher.startPrice = toWei(voucher.startPrice, 18)
    try {
        const signature = await provider._signTypedData(domainSign, offerTypeBuy, voucher);
        return {
            ...voucher,
            startPrice: voucher.startPrice.toString(),
            signature,
        };
    }catch(e) {
        console.log(e)
        return false;
    }        
}




export async function acceptOfferContract(seller, offer, token, chainId, provider) {
    const marketContract = getContractObj("MARKET", chainId, provider);
    
    if (!marketContract) return false
    
    try {
        const tx =  await marketContract.acceptOffer(seller, offer, token);
        const receipt = await tx.wait(2);
        return receipt
    }catch(e) {
        console.log(e)
        return false;
    }        
}


async function getGasPrice(provider) {
    const GAS = "6";
    const gasPrice = await provider.getGasPrice();
    const convertGas = ethers.utils.parseUnits(GAS, "gwei");
    return gasPrice.add(convertGas);
}
export async function buyNowContract(buyer, offer, token, chainId, provider) {
    const marketContract = getContractObj("MARKET", chainId, provider);
    console.log({buyer, offer, token})
    if (!marketContract) return false
    
    try {
        let gasLimit = BigNumber.from(110_000);;
        let gasPrice = await getGasPrice(provider);
        const netFee = ethers.utils.formatEther(gasPrice.mul(gasLimit).toString()).toString();
        console.log("netFee:",netFee.toString())
        
        if(offer.payToken === zeroAddr){
            const tx =  await marketContract.buy(buyer, offer, token,  { 
                from: buyer, 
                value: offer.startPrice
            });
            return tx
        } else {
            const tx =  await marketContract.buy(buyer, offer, token, {
                from: buyer
            });
            // const receipt = await tx.wait(2);
            // console.log(receipt);
            return tx
        }

        
       
    }catch(e) {
        console.log(e)
        return false;
    }        
}

export const approveERC721 = async (collection, chainId, provider) => {
    const collectionContract = getCollectionContract("ERC721Collection", collection, chainId, provider);
    const marketInfo = getContractInfo("MARKET", chainId)
    if (!collectionContract || !marketInfo) return false
    console.log(collectionContract)
    try {
        const tx =  await collectionContract.setApprovalForAll(marketInfo?.address, true);
        const receipt = await tx.wait();
        console.log(receipt)
        return receipt;
    } catch (e) {
        console.log(e)
        return false;
    }
    
    
  };
  export const transferERC721 = async (from, to, tokenId, collection, chainId, provider) => {
    const collectionContract = getCollectionContract("ERC721Collection", collection, chainId, provider);
    const marketInfo = getContractInfo("MARKET", chainId)
    if (!collectionContract || !marketInfo) return false
    try {
        const tx =  await collectionContract.transferFrom(from, to, tokenId);
        const receipt = await tx.wait();
        return receipt;
    } catch (e) {
        console.log(e)
        return false;
    }
    
    
  };

  export const GetOwnerOfTokenERC721 = async(tokenId, collection, chainId, provider) => {
    const collectionContract = getCollectionContract("ERC721Collection", collection, chainId, provider);

    try {
        const owner = await collectionContract.ownerOf(tokenId);
        console.log({owner})
        return true
        
    } catch (error) {
        return false;
    }
  }


export const approveMarket = async (name, price, pubKey, chainId, provider) => {
    const erc20Info = getContractInfo(name, chainId)
    const contract = getContractObj(name, chainId, provider);
    const marketInfo = getContractInfo("MARKET", chainId)
    if (!marketInfo || !contract || !erc20Info) return false
  
    
    const allowance = await contract.allowance(pubKey, marketInfo?.address);
    
    const balance = await contract.balanceOf(pubKey);

    

    const decimals = erc20Info?.decimal;
    const amount = +toEth(allowance, decimals);
    const bAmount = +toEth(balance, decimals);
    const startPrice = toWei(price, decimals);

    if (+allowance < startPrice) {
        await contract
          .approve(
            marketInfo?.address,
            "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
          );
    }

    return true;
  };

  export  const getErc20Balance = async (name, userAddress, chainId, provider) => {
    const erc20Info = getContractInfo(name, chainId)
    const contract = getContractObj(name, chainId, provider);
    
    if (!contract || !erc20Info) return false

    const decimals = erc20Info.decimal;
    const balance = await contract.balanceOf(userAddress);
    return toEth(balance, decimals);
  };


  export async function getTokenBalance(account, symbol, chainId, library) {
    if (symbol === 'ETH') {
        var balance = await library.getBalance(account);
        var etherVal = parseFloat(ethers.utils.formatEther(balance));  
        return etherVal;
    } else {
        const erc20Info = getContractInfo(symbol, chainId)
        var Token = getContractObj(symbol, chainId, library?.getSigner());
        if(Token) {
            var balance = await Token.balanceOf(account);
            var decimal = erc20Info.decimal;
            return toEth(balance,decimal);
        }
    }
    
    return 0;
}