import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { chainIds, currencys, networks, prcUrls } from "../utils";
import { getErrorMessage } from "../utils/ethereum";
import { setupNetwork } from "../utils/wallet";
import { useSnackbar } from "./SnackbarContext";

const WalletContext = createContext({});
const POLLING_INTERVAL = 12000;
export const SUPPORTED_CONNECTORS = ["injected", "walletconnect"];
// export const SUPPORTED_CONNECTORS = ["injected", "walletconnect", "coinbase"];
export const injected = new InjectedConnector({
  supportedChainIds: chainIds,
});

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: chainIds,
  rpc: prcUrls,
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
  bridge: 'https://bridge.walletconnect.org',
  qrcodeModalOptions: {
    mobileLinks: [
      "rainbow",
      "metamask",
      "argent",
      "trust",
      "imtoken",
      "pillar",
    ],
  },
});

export const CoinbaseWallet = (chainID) => {
  return new WalletLinkConnector({
    url: prcUrls[chainID],
    appName: "E-NFT World",
    appLogoUrl: "http://demo.enftworld.com/static/media/logo.84f1eeeb8b409957439a.png"
   })
};

export const resetWalletConnector = (connector) => {
  if (
    connector &&
    connector instanceof WalletConnectConnector &&
    connector.walletConnectProvider?.wc?.uri
  ) {
    connector.walletConnectProvider = undefined;
  }
};



export const WalletProvider = ({ children }) => {
  const {
      showSnackbar
  } = useSnackbar();
  const {
      connector,
      account,
      activate,
      active,
      deactivate,
      chainId,
      library,
      ...r
  } = useWeb3React();


  useEffect(() => {
        const connected = localStorage.getItem("connected");
        connect(connected)
        
        return () => {};
  }, [active, activate]);

  const signer = useMemo(() => {
    return library?.getSigner()
  }, [library])


  const connect = async (type) => {
      try {
          if (!SUPPORTED_CONNECTORS.includes(type)) {
              return;
          }
          let currentConnector =  injected;
          if(type === "walletconnect") {
            currentConnector = walletconnect
          } else if(type === "injected") {
            currentConnector = injected
          } else if (type === "coinbase") {
            currentConnector = CoinbaseWallet(chainId)
          } else {
            return;
          }
          
          localStorage.setItem("connected", type);

          activate(currentConnector, async (error) => {
            const errorMsg = getErrorMessage(error);
            showSnackbar({
              type: 'error',
                message: errorMsg
            })
            if (error instanceof UnsupportedChainIdError) {
              
              const hasSetup = await setupNetwork()
              if (hasSetup) {
                activate(currentConnector)
              }
              if (currentConnector instanceof WalletConnectConnector){
                await deactivate();
               
              }

            }
          });

       

      } catch (error) {
          console.log(error)
      }
  };

  const showWrongNetworkWarning = (network) => {
    showSnackbar({
      type: "warning",
      message: `Please switch to ${network}`,
    });
  };

  const disconnect = async () => {
      await deactivate();
      localStorage.setItem("connected", "n");
      localStorage.removeItem('token');
      localStorage.removeItem('user');
  };

  return (
    <WalletContext.Provider
      value={{
        library,
        account: account?.toLowerCase(),
        connect,
        disconnect,
        chainId,
        signer,
        network: networks[chainId],
        currency: currencys[chainId],
        connector,
        showWrongNetworkWarning
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);