import React, { createContext, useContext, useReducer } from 'react';
import spinnerReducer from './reducer';

const initialState = {
    spinner: false,
    openSpinner: () => null,
    closeSpinner: () => null
}

const SpinnerContext = createContext(initialState);


export const SpinnerProvider = ({ children }) => {
    const [spinnerState, dispatch] = useReducer(spinnerReducer, false);

    const openSpinner = () => {
        dispatch({ type: "OPEN_SPINNER" });
    };

    const closeSpinner = () => {
        dispatch({ type: "CLOSE_SPINNER" });
    };

    return (
        <SpinnerContext.Provider
            value={{ spinner: spinnerState, openSpinner: openSpinner, closeSpinner: closeSpinner }}>
            {children}
        </SpinnerContext.Provider>
    );
};

export const useSpinner = () => useContext(SpinnerContext);