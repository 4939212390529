export const navbarLink = [
    {
      name: "Explore",
      path: "/explore",
      auth: false
    },
    {
      name: "Create",
      path: "/create",
      auth: true
    },
    {
      name: "Rankings",
      path: "/rankings",
      auth: false
    },
  ];
  