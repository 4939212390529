const routes = {
    home: '/',
    explore: '/explore',
    create: '/create',
    edit:'edit/:tokenId',
    ranking: '/rankings',
    profileDetail: '/edit-profile/:tokenId',
    listItemDetail: '/list/:tokenId',
    detailCollection: '/collection/:address',
    search: '/search',
    itemDetail: '/item/:tokenId',
    category: '/category/:q_category',
    connectWallet: '/connect-wallet',
    profile: '/user/:address',
    myCollection: '/my-collection',
    createCollection: '/create-collection',
    notfound:'*',
};

export default routes;
