import { Contract } from '@ethersproject/contracts'
import { utils, BigNumber, ethers } from "ethers";

import erc20Abi from "./abis/EbizworldToken.json";
import erc721CollectionAbi from "./abis/ERC721Collection.json";
import erc721FactoryAbi from "./abis/ERC721CollectionFactory.json"

import erc1155CollectionAbi from "./abis/ERC1155Collection.json"
import erc1155factoryAbi from "./abis/ERC1155CollectionFactory.json";

import marketAbi from "./abis/EbizworldMarket.json"

const SIGNING_DOMAIN_NAME = "EbizworldVerseMarket";
const SIGNING_DOMAIN_VERSION = "1";

export const chainIds = [5,31337,80001, 97]

export const NetworkName = (chainId) => {
    switch (chainId) {
        case 0:
            return "Not Found";
        case 1:
            return "Etheruem Mainnet";
        case 5:
            return "Ethereum testnet | Goerli";
        case 56:
            return "BSC Mainnet";
        case 97:
            return "BSC Testnet";
        case 80001:
            return "Mumbai | Polygon Testnet";
    }
};

export const networks = {
    4: "ETHEREUM",
    5: "ETHEREUM",
    31337: "ETHEREUM",
    80001: "POLYGON",
    97: "BINANCE"
}
export const currencys = {
    4: "ETH",
    5: "ETH",
    31337: "ETH",
    80001: "MATIC",
    97: "BNB"
}
export const prcUrls = {
    4: "https://rinkeby.infura.io/v3/95d2543bd48146f582fb7a248828d262",
    5: "https://goerli.infura.io/v3/95d2543bd48146f582fb7a248828d262",
    31337: "http://127.0.0.1:8545",
    80001: "https://rpc-mumbai.maticvigil.com",
    97: "https://data-seed-prebsc-1-s1.binance.org:8545"
}
export const chainConfig = {
    5: {
        chainId: 5,
        chainName: "Ethereum Testnet | Goerli",
        symbol: "ETH",
        rpc: "https://goerli.infura.io/v3/95d2543bd48146f582fb7a248828d262",
        explorer: "https://goerli.etherscan.io",
    },
    97: {
        chainId: 97,
        chainName: "Binance Testnet",
        symbol: "BNB",
        rpc: "https://data-seed-prebsc-1-s1.binance.org:8545",
        explorer: "https://testnet.bscscan.com",
    }
}
export const zeroAddr = "0x0000000000000000000000000000000000000000"
export const CONTRACTS_BY_NETWORK = {
    4: {
        ETH: {
            address: '0x0000000000000000000000000000000000000000',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        WETH: {
            address: '0x01BE23585060835E02B77ef475b0Cc51aA1e0709',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        EBZ: {
            address: '0xC3a448181247df23B0417196835b426a13fFadF4',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        ERC721Collection: {
            address: '0x73D2850393d81956C7EB22Ef8B09a3638578A081',
            abi: erc721CollectionAbi.abi
        },
        ERC1155Collection: {
            address: '0x2EFD94C4f40B0F81eC1762144c809614C9D2A8Db',
            abi: erc1155CollectionAbi.abi
        },
        ERC721Factory: {
            address: '0xC9961404dC7559A20bbd925d0484D38e9e1c3A9F',
            abi: erc721FactoryAbi.abi
        },
        ERC1155Factory: {
            address: '0x05B07CDA7FF404d176645975F87d8aAAd55caf96',
            abi: erc1155factoryAbi.abi
        },
        MARKET: {
            address: "0x9F1b95b61CCfE338DC9B5057097C1a76D769A0cA",
            abi: marketAbi.abi,
        }
    },  
    5: {
        ETH: {
            address: '0x0000000000000000000000000000000000000000',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        WETH: {
            address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        EBZ: {
            address: '0x0466ab50B465deedBC1cFfAca2b292fa00345e2E',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        ERC721Collection: {
            address: '0x73D2850393d81956C7EB22Ef8B09a3638578A081',
            abi: erc721CollectionAbi.abi
        },
        ERC1155Collection: {
            address: '0x2EFD94C4f40B0F81eC1762144c809614C9D2A8Db',
            abi: erc1155CollectionAbi.abi
        },
        ERC721Factory: {
            address: '0xC02F5b7dD9f151782380284a281F2407A8Fb7663',
            abi: erc721FactoryAbi.abi
        },
        ERC1155Factory: {
            address: '0xF05Ac1cD18AED798A8809E499234Fcde7647b27a',
            abi: erc1155factoryAbi.abi
        },
        MARKET: {
            address: "0x5fcc92747FfF558Bc97e8984dAd51D2cb1afF1d5",
            abi: marketAbi.abi,
        }
    },
    31337: {
        ETH: {
            address: '0x0000000000000000000000000000000000000000',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        WETH: {
            address: '0x01BE23585060835E02B77ef475b0Cc51aA1e0709',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        EBZ: {
            address: '0xF1A02D13BdE04936455a6d6d65B804385020F0cb',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        ERC721Collection: {
            address: '0xB7E59fd873DfC169196553e7C5E035993bDf5e7d',
            abi: erc721CollectionAbi.abi
        },
        ERC1155Collection: {
            address: '0xFF9F15F02cf1302B1164e4Ec6a44D53f27Ed5D82',
            abi: erc1155CollectionAbi.abi
        },
        ERC721Factory: {
            address: '0xf42AC7829A77BC9fD157B19d34e668376fB11652',
            abi: erc721FactoryAbi.abi
        },
        ERC1155Factory: {
            address: '0x6f37455f4eEB2F0aD5588E1bE2BC4f1Ff1725BE7',
            abi: erc1155factoryAbi.abi
        },
        MARKET: {
            address: "0x3f2E19dc6ffd23300600FfDA3219c215E00F1725",
            abi: marketAbi.abi,
        }
    }, 
    80001: {
        MATIC: {
            address: '0x0000000000000000000000000000000000000000',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        WETH: {
            address: '0x01BE23585060835E02B77ef475b0Cc51aA1e0709',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        EBZ: {
            address: '0xcF86AEd82E37cBba003ec6CdDFe0261E220a4837',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        ERC721Collection: {
            address: '0xB7E59fd873DfC169196553e7C5E035993bDf5e7d',
            abi: erc721CollectionAbi.abi
        },
        ERC1155Collection: {
            address: '0xFF9F15F02cf1302B1164e4Ec6a44D53f27Ed5D82',
            abi: erc1155CollectionAbi.abi
        },
        ERC721Factory: {
            address: '0xe6a8096FAa9CdBe142c03c86eDb27E327c78D7Bc',
            abi: erc721FactoryAbi.abi
        },
        ERC1155Factory: {
            address: '0xdF7474eed49494B670f28A1DEB6425Ac1a99fb6B',
            abi: erc1155factoryAbi.abi
        },
        MARKET: {
            address: "0x9eD77530E59cD6efc0622938feAC907218D22782",
            abi: marketAbi.abi,
        }
    }, 
    97: {
        BNB: {
            address: '0x0000000000000000000000000000000000000000',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        WETH: {
            address: '0x01BE23585060835E02B77ef475b0Cc51aA1e0709',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        EBZ: {
            address: '0xC1CAE517E3eE493A0DcaBa3C173C77C8285F67D9',
            abi: erc20Abi.abi,
            decimal: 18,
        },
        ERC721Collection: {
            address: '0xB7E59fd873DfC169196553e7C5E035993bDf5e7d',
            abi: erc721CollectionAbi.abi
        },
        ERC1155Collection: {
            address: '0xFF9F15F02cf1302B1164e4Ec6a44D53f27Ed5D82',
            abi: erc1155CollectionAbi.abi
        },
        ERC721Factory: {
            address: '0x782f1f75f2048aA02287EBF1027753406738dECF',
            abi: erc721FactoryAbi.abi
        },
        ERC1155Factory: {
            address: '0xDC7E254d7463c3a11fe1338851eF35Cd98196096',
            abi: erc1155factoryAbi.abi
        },
        MARKET: {
            address: "0xF5911C34fE86ba518D620cF3EBff21ba981a6909",
            abi: marketAbi.abi,
        }
    }, 
  }

export function getDomainSign(chainId, address) {
    return {
        name: SIGNING_DOMAIN_NAME,
        version: SIGNING_DOMAIN_VERSION,
        verifyingContract: address,
        chainId: chainId
    }
}

export function nftVoucher(collectionAddress, creator, tokenId, uri, royalty, quantity, nonce) {
    return ({
        nftAddress: collectionAddress,
        creator: creator,
        tokenId: tokenId,
        tokenURI: uri,
        royalty: royalty,
        quantity: quantity,
        nonce: nonce
    })
}

export function offerVoucher(lazy, collectionAddress,tokenId, quantity, seller, buyer, startPrice, payToken, startTime, endTime,nonce) {
    return ({
        lazy: lazy,
        nftAddress: collectionAddress,
        tokenId: tokenId,
        quantity:quantity,
        seller: seller,
        buyer: buyer,
        startPrice: startPrice,
        payToken: payToken,
        startTime: startTime,
        endTime: endTime,
        nonce: nonce,
    })
}


export const tokenType = {
    MintData: [
      {
        name: "nftAddress",
        type: "address",
      },
      {
        name: "creator",
        type: "address",
      },
      {
        name: "tokenId",
        type: "uint256",
      },
      {
        name: "tokenURI",
        type: "string",
      },
      {
        name: "royalty",
        type: "uint256",
      },
      {
        name: "quantity",
        type: "uint256",
      },
      {
        name: "nonce",
        type: "uint256",
      }
    ]
}

export const offerTypeBuy = {
        Offer: [
            {
                name: "lazy",
                type: "bool"
            },
            {
                name: "nftAddress",
                type: "address"
            },
            {
                name: "tokenId",
                type: "uint256"
            },
            {
                name: "quantity",
                type: "uint256"
            },
            {
                name: "seller",
                type: "address"
            },
            {
                name: "buyer",
                type: "address"
            },
            {
                name: "startPrice",
                type: "uint256"
            },
            {
                name: "payToken",
                type: "address"
            },
            {
                name: "startTime",
                type: "uint256"
            },
            {
                name: "endTime",
                type: "uint256"
            },
            {
                name: "nonce",
                type: "uint256"
            },
        ],
}

export const loginDomain = {
    name: SIGNING_DOMAIN_NAME,
    version: SIGNING_DOMAIN_VERSION
}

export const loginTypeSign = {
    Message: [{
            name: "notify",
            type: "string",
        },
        {
            name: 'owner',
            type: 'address'
        },
        {
            name: "nonce",
            type: "uint256",
        }
    ],
}


export const generateTokenId = (cid) =>
  BigNumber.from(
    utils.keccak256(utils.defaultAbiCoder.encode(["string"], [cid]))
  ).toString();

export function generateID(cid) {
    return BigNumber.from(
        utils.keccak256(utils.defaultAbiCoder.encode(["string"], [cid]))
      ).toString();
}

export function generateNonce() {
    return Math.floor(Math.random() * 1000000);
}

export function getContractInfo(name, chainId = null) {
    if(!chainId) chainId = 5;
  
    const contracts = CONTRACTS_BY_NETWORK?.[chainId];  
    if(contracts) {
      return contracts?.[name];
    }else{
      return null;
    }
}


export function getContractObj(name, chainId, provider) {
    const info = getContractInfo(name, chainId);
    
    return !!info && new Contract(info.address, info.abi, provider);
}

export function getCollectionContract(name, address, chainId, provider) {
    const info = getContractInfo(name, chainId);
    return !!info && new Contract(address, info.abi, provider);
}



export const networkInfo= {
	mainnet: {
		chainId: 1,
		name: 'Ethereum',
		id: 'mainnet',
		wyreSRN: 'ethereum',
		testnet: false,
		etherscanURL: 'https://etherscan.io/',
		etherscanAPIURL: 'https://api.etherscan.io/',
		etherscanAPIKey: 'R3NFBKJNVY4H26JJFJ716AK8QKQKNWRM1N',
		zapperNetwork: 'ethereum',
		nativeToken: { symbol: 'ETH', name: 'Ethereum' },
		topUpTokens: [
			{ symbol: 'USDC', address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', decimals: 6 },
			{ symbol: 'ETH', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 },
			{ symbol: 'USDT', address: '0xdAC17F958D2ee523a2206206994597C13D831ec7', decimals: 6 }
		],
		transactionTimesEndpoint: true,
		apiUrl0x: 'https://api.0x.org/',
		alchemyAPIKey:  process.env.ALCHEMY_API_KEY_ETHEREUM,
		coingeckoPlatform: 'ethereum'
	},
	matic: {
		chainId: 137,
		name: 'Polygon',
		id: 'matic',
		wyreSRN: 'matic',
		testnet: false,
		etherscanURL: 'https://polygonscan.com/',
		etherscanAPIURL: 'https://api.polygonscan.com/',
		etherscanAPIKey: 'ETKTPMXNC3VEPFQY9D3UZCS47IGQH7FDS7',
		zapperNetwork: 'polygon',
		nativeToken: { symbol: 'MATIC', name: 'Matic' },
		transactionTimesEndpoint: false,
		jsonRpcProvider:  process.env.ALCHEMY_API_URL_POLYGON_MAINNET,
		biconomyAPIKey:  process.env.BICONOMY_API_KEY_POLYGON_MAINNET,
		topUpTokens: [
			{ symbol: 'USDC', address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', decimals: 6 },
			{ symbol: 'MATIC', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }
		],
		apiUrl0x: 'https://polygon.api.0x.org/',
		alchemyAPIKey:  process.env.ALCHEMY_API_KEY_MAT,
		mStable: {
			depositContract: '0x89252e32e98D467C6Ae3e2E6a9942bA3033f938a',
			withdrawContract: '0x89252e32e98D467C6Ae3e2E6a9942bA3033f938a',
			mAsset: '0xE840B73E5287865EEc17d250bFb1536704B43B21',
			saveAsset: '0x5290Ad3d83476CA6A2b178Cd9727eE1EF72432af',
			vault: '0x32aBa856Dc5fFd5A56Bcd182b13380e5C855aa29'
		},
		coingeckoPlatform: 'polygon-pos'
	},
	kovan: {
		chainId: 42,
		name: 'Kovan',
		id: 'kovan',
		wyreSRN: 'ethereum',
		testnet: true,
		etherscanURL: 'https://kovan.etherscan.io/',
		etherscanAPIURL: 'https://api-kovan.etherscan.io/',
		etherscanAPIKey: 'R3NFBKJNVY4H26JJFJ716AK8QKQKNWRM1N',
		zapperNetwork: 'ethereum',
		nativeToken: { symbol: 'ETH', name: 'Ethereum' },
		topUpTokens: [{ symbol: 'ETH', address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', decimals: 18 }],
		transactionTimesEndpoint: true,
		alchemyAPIKey:  process.env.ALCHEMY_API_KEY_KOVAN,
		coingeckoPlatform: 'ethereum'
	}
};