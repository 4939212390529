import config from '../config';
import { lazy } from 'react';
// Layouts
import HeaderOnlyLayout from '../components/layout/HeaderOnlyLayout'; 
const Home = lazy(() =>
  import("../pages/Home")
);
const ExploreCollection = lazy(() =>
  import("../pages/ExploreCollection")
);
const Create = lazy(() =>
  import("../pages/Create")
);
const Edit = lazy(() =>
import("../pages/Edit")
);
const Rankings = lazy(() =>
import("../pages/Rankings")
);
const ProfileDetail = lazy(() =>
import("../pages/ProfileDetail")
);
const ListItemDetail = lazy(() =>
import("../pages/ListItemDetail")
);
const DetailCollection = lazy(() =>
import("../pages/DetailCollection")
);
const Search = lazy(() =>
import("../pages/Search")
);
const ItemDetail = lazy(() =>
import("../pages/ItemDetail")
);
const ConnectWallet = lazy(() =>
import("../pages/ConnectWallet")
);
const Profile = lazy(() =>
import("../pages/Profile")
);
const Collections = lazy(() =>
import("../pages/Collections")
);
const CreateCollection = lazy(() =>
import("../pages/CreateCollection")
);
const NotFound = lazy(() =>
import("../pages/NotFound")
);
const Category = lazy(() =>
import("../pages/Category")
);


// Public routes
export const publicRoutes = [
    { path: config.routes.home, component: Home },
    { path: config.routes.explore, component: ExploreCollection },
    { path: config.routes.create, component: Create, layout: HeaderOnlyLayout },
    { path: config.routes.edit, component: Edit, layout: HeaderOnlyLayout },
    { path: config.routes.ranking, component: Rankings, layout: HeaderOnlyLayout },
    { path: config.routes.profileDetail, component: ProfileDetail, layout: HeaderOnlyLayout }, 
    { path: config.routes.listItemDetail, component: ListItemDetail, layout: HeaderOnlyLayout },
    { path: config.routes.detailCollection, component: DetailCollection },
    { path: config.routes.search, component: Search, layout: HeaderOnlyLayout  },
    { path: config.routes.itemDetail, component: ItemDetail  },
    { path: config.routes.category, component: Category  },
    { path: config.routes.connectWallet, component: ConnectWallet },
    { path: config.routes.profile, component: Profile },
    { path: config.routes.myCollection, component: Collections },
    { path: config.routes.createCollection, component: CreateCollection, layout: HeaderOnlyLayout },
    { path: config.routes.notfound, component: NotFound, layout: HeaderOnlyLayout },
];

export const privateRoutes = [];
