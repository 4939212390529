import {
  Box,
  CardMedia,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack
} from "@mui/material";
import React from "react";
import {
  LinkFooter,
  TitleFooter,
  WrapperContainer
} from "../../../../styles/home";
//IMG
import { useNavigate } from "react-router-dom";
import facebook from "../../../../assets/images/footer/facebook.png";
import gmail from "../../../../assets/images/footer/gmail.png";
import instagram from "../../../../assets/images/footer/instagram.png";
import twitter from "../../../../assets/images/footer/Twitter.png";
import youtube from "../../../../assets/images/footer/youtube.png";
import logo from "../../../../assets/images/header/logo.png";
const data = [
  {
    id: 1,
    title: "All NFTs",
    link: "/category/all",
  },
  {
    id: 2,
    title: "Art",
    link: "/category/Art",
  },
  {
    id: 3,
    title: "Collectibles",
    link: "/category/Collectibles",
  },
  {
    id: 4,
    title: "Music",
    link: "/category/Music",
  },
  {
    id: 5,
    title: "Photography",
    link: "/category/Photography",
  },
  {
    id: 6,
    title: "Domains",
    link: "/category/Domains",
  },
  {
    id: 7,
    title: "Sports",
    link: "/category/Sports",
  },
];
const dataCompany = [
  {
    id: 1,
    title: "About",
    link: "/about",
  },
];
const dataComunity = [
  {
    id: 1,
    src: twitter,
    alt: "twitter",
    link: "https://twitter.com/NFT_NEO",
  },
  {
    id: 2,
    src: instagram,
    alt: "instagram",
    link: "https://www.instagram.com/nft_neo/",
  },
  {
    id: 3,
    src: facebook,
    alt: "facebook",
    link: "https://www.facebook.com/NFT_NEO/",
  },
  {
    id: 4,
    src: youtube,
    alt: "youtube",
    link: "https://www.youtube.com/channel/UC-lHJZR3Gqxm24_Vd_AJ5Yw",
  },
  {
    id: 5,
    src: gmail,
    alt: "gmail",
    link: "https://mail.google.com/mail/u/0/#inbox",
  },
];
const Footer = () => {
  const navigation = useNavigate();
  const gotoHomePage = () => {
    navigation("/");
  };

  return (
    <WrapperContainer>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{ height: "40px", cursor: "pointer" }}
            onClick={gotoHomePage}
          >
            <CardMedia
              sx={{ height: "100%", width: "100%", objectFit: "contain" }}
              component={"img"}
              src={logo}
              alt="logo"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TitleFooter>Marketplace</TitleFooter>
          <List dense={true}>
            {data.map((item) => {
              return (
                <ListItem sx={{ paddingLeft: "unset" }} key={item.id}>
                  <LinkFooter to={item.link}>
                    <ListItemText primary={item.title} />
                  </LinkFooter>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TitleFooter>Rackings</TitleFooter>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TitleFooter>Company</TitleFooter>
          <List dense={true}>
            {dataCompany.map((item) => {
              return (
                <ListItem sx={{ paddingLeft: "unset" }} key={item.id}>
                  <LinkFooter to={item.link}>
                    <ListItemText primary={item.title} />
                  </LinkFooter>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TitleFooter>Join the community</TitleFooter>
          <Stack
            sx={{ marginTop: "8px" }}
            direction="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            spacing={1.5}
            alignItems="center"
          >
            {dataComunity.map((item) => {
              return (
                <Link href={item.link} key={item.id}>
                  <img
                    sx={{ width: 30, height: 30 }}
                    alt={item.alt}
                    src={item.src}
                  />
                </Link>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </WrapperContainer>
  );
};

export default Footer;
