import React, { useState } from "react";

import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import CssBaseLine from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import "swiper/css/bundle";
import Signature from "./components/common/signature";
import Spinner from "./components/common/spinner";
import MainLayout from "./components/layout/MainLayout";
import "./css/custom-editor.css";
import "./css/custom-scrollbar.css";
import { publicRoutes } from "./routes";
import theme from "./styles/theme";

function App() {
    const [proccess,setProccess] = useState(0)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseLine />
      <BrowserRouter>
        <Routes>
          {publicRoutes.map((route, index) => {
            const Page = route.component;
            let Layout = MainLayout;

            if (route.layout) {
              Layout = route.layout;
            } else if (route.layout === null) {
              Layout = Fragment;
            }

            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <Layout>
                     <LoadingBar color="#f11946" progress={proccess} shadow={true}/>
                    <Signature/>
                    <Spinner />
                    <Page setProgress={setProccess}/>
                  </Layout>
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
