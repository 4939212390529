import { useWeb3React } from "@web3-react/core";
import { createContext, useContext, useEffect, useState } from "react";
import userApi from "../api/userApi";
import { eventTrack } from "../hooks/useAnalyticsEventTracker";
import { loginDomain, loginTypeSign } from "../utils";
import { cache } from "../utils/cache";
import { getTokenBalance } from "../utils/contracts";
import { useSnackbar } from "./SnackbarContext";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [click, setClick] = useState(false);
  const { showSnackbar } = useSnackbar();
  const { account, active, library, chainId } = useWeb3React();
  const [balance, setBalance] = useState(0);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);

  
  const handleSignMessage = async (nonce, signer) => {

    try {
        const voucher = {
            notify: `Welcome to Ebizworld!`,
            owner: account,
            nonce
        }
        return await signer._signTypedData(loginDomain,loginTypeSign,voucher);
      } catch (err) {
          showSnackbar({
              type: "error",
              message: "You need to sign the message to be able to log in."
          })
      }
  };

  useEffect(() => {
    if (active && account) {
      if(!user || user?.address != account.toLowerCase()) {
        userApi.get(account).then(resUser => {
            handleSignMessage(resUser.user.nonce, library.getSigner()).then((signature) => {
                userApi.login(account, signature || "").then(resLogin => {
                    const storage = cache("token")
                    storage.set(resLogin.token);
                    setUser(resLogin.user);
                    const storageUser = cache("user")
                    storageUser.set(JSON.stringify(resLogin.user))
                    eventTrack("ConnectWallet", "Login", "Button")
                    showSnackbar({
                        type: "success",
                        message: "Wallet connected"
                    })
                    setIsOpen(false);
                }).catch(err => {
                    if (err && err?.data && err?.data?.error) {
                        showSnackbar({
                            type: "error",
                            message: err?.data?.error
                        })
                    }
                })
            })
        })
      }
        
    }
  }, [active])

  useEffect(() => {
    if (window.ethereum) {
      async function listenMMAccount() {
        window.ethereum.on("accountsChanged", async function () {
          let dataUser = await JSON.parse(localStorage.getItem('user')) || null;
          if(!dataUser || dataUser?.address != account?.toLowerCase()) {
            setIsOpen(true);
            localStorage.removeItem('token');
            localStorage.removeItem('user');
          }
        });
      }
      listenMMAccount();
    }
  }, []);

  useEffect(() => {
    getBalance();
  }, [user, library]);

  const getBalance = async () => {
    if (!user || !account) {
      setBalance(0);
      return;
    }
    const value = await getTokenBalance(account, "ETH", chainId, library);
    setBalance(parseFloat(value).toFixed(4));
  };

  const refreshToken = async () => {
    const storage = cache("token")
    const token = storage.get();
    if (token) {
      userApi.refreshToken(token).then(res => {
        if (res && res.token) {
          storage.set(res.token);
        }
      })
    }
  }

  function SignAccount() {
    let dataUser = JSON.parse(localStorage.getItem('user')) || null;

    if(dataUser && dataUser?.address === account?.toLowerCase()) {
      setIsOpen(false);
      setClick(false);
      return;
    }

    if(!user || user?.address != account.toLowerCase()) {
      userApi.get(account).then(resUser => {
          handleSignMessage(resUser.user.nonce, library.getSigner()).then((signature) => {
              userApi.login(account, signature || "").then(resLogin => {
                  const storage = cache("token")
                  storage.set(resLogin.token);
                  setUser(resLogin.user);
                  const storageUser = cache("user")
                  storageUser.set(JSON.stringify(resLogin.user))
                  eventTrack("ConnectWallet", "Login", "Button")
                  showSnackbar({
                      type: "success",
                      message: "Wallet connected"
                  })
                  setIsOpen(false);
                  setClick(false);
              }).catch(err => {
                  if (err && err?.data && err?.data?.error) {
                      showSnackbar({
                          type: "error",
                          message: err?.data?.error
                      })
                  }
                  setClick(false);
              })
          })
      })
    } else {
      setIsOpen(false);
      setClick(false);
    }
  }
  const updateUser = async (user) => {
    let currentUser = JSON.parse(localStorage.getItem('user'));
    const storageUser = cache("user")
    const newUser = {
      ...currentUser,
      ...user
    }
    setUser(newUser)
    storageUser.set(JSON.stringify(newUser))
  }
  return (
    <AuthContext.Provider
      value={{
        user,
        balance,
        modalIsOpen,
        SignAccount,
        click,
        setClick,
        updateUser,
        refreshToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
