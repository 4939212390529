const spinnerReducer = (state, action) => {
    switch (action.type) {
        case "OPEN_SPINNER":
            return true;
        case "CLOSE_SPINNER":
            return false;
        default:
            return state;
    }
};

export default spinnerReducer;
