import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import { Buffer } from "buffer";
import { Suspense } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactGA from "react-ga";
import { AuthProvider } from "./contexts/AuthContext";
import { CoinPriceProvider } from "./contexts/CoinPriceContext";
import { CollectionProvider } from "./contexts/CollectionContext";
import { ConfirmProvider } from "./contexts/ConfirmContext";
import { SnackbarProvider } from "./contexts/SnackbarContext";
import { SpinnerProvider } from "./contexts/SpinnerContext";
import { WalletProvider } from "./contexts/WalletContext";
const TRACKING_ID = "UA-241683580-2";
ReactGA.initialize(TRACKING_ID);

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}
window.Buffer = window.Buffer || Buffer;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <Web3ReactProvider getLibrary={getLibrary}>
 
      <Suspense fallback={<div style={{ display: "none" }}>Loading...</div>}>
        <SpinnerProvider>
          <SnackbarProvider>
            <ConfirmProvider>
              <WalletProvider>
                <CoinPriceProvider>
                  <AuthProvider>
                    <CollectionProvider>
                      <App />
                    </CollectionProvider>
                  </AuthProvider>
                </CoinPriceProvider>
              </WalletProvider>
            </ConfirmProvider>
          </SnackbarProvider>
        </SpinnerProvider>
      </Suspense>
  
  </Web3ReactProvider>
);
