import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import {
  IconButton, Stack,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { navbarLink } from "../../assets/data/navbar";
import { useWallet } from "../../contexts/WalletContext";
import { ColorButton } from "../../styles/component/button";
export default function ToggleDrawer(props) {
  const { setIsOpen, isOpen, account,user ,settings} = props;
  const [next, setNext] = React.useState(false);
  const navigation = useNavigate();
  const {disconnect} = useWallet()
  const nextLink = () => {
    setNext(!next);
  };
  const menuClick = (menu) => {
    // setAnchorElUser(null);
    if(menu === 10){
      navigation("/connect-wallet");
      setIsOpen(false);
    }
    if(menu.id !== 5){
      if(menu.id === 1){
        navigation(`/user/${account.toLowerCase()}`);
        setIsOpen(false);
      }
      if(menu.id === 3){
        navigation('/my-collection')
        setIsOpen(false);
      }
      if(menu.id === 4) {
        navigation('/profile-detail')
        setIsOpen(false);
      }
    } else {
      disconnect();
      setNext(false);
      setIsOpen(false);
      window.location.href = "/connect-wallet";
    }
  }
  const menuClick1 = (menu) => {
    // setAnchorElUser(null);
    console.log(menu)
      if(menu === 0){
        navigation('/explore');
        setIsOpen(false);
      }
      if(menu === 1){
        navigation('/create');
        setIsOpen(false);
      }
      if(menu === 2) {
        navigation('/rankings')
        setIsOpen(false);
      }
  }
  const list = () => (
   
    <Box
      sx={{
        width: { xs: '100%' },
        background:
          "radial-gradient(97.1% 97.1% at 10.37% 4.33%, #56132B 0%, #3E0D39 100%)",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        alignItems: "center",
        justifyContent: "center",
      }}
      role="presentation"

    >
      <IconButton
        size="large"
        color="inherit"
        aria-label="menu"
        sx={{ position: "absolute", top: "10px", right: "10px" }}
        onClick={() => setIsOpen(false)}
      >
        <CloseIcon fontSize="large" sx={{ color: "#fff" }} />
      </IconButton>
      <Stack spacing={3} direction="column" alignItems={"center"}>
        {navbarLink.map((page, index) => (
          <Typography key={index} sx={{
            color: "#fff",
            fontWeight: 600,
            fontSize: "1em",
          }}
          onClick={() => menuClick1(index)}>
              {page.name}
          </Typography>
        ))}
      </Stack>
     
       {
        account && user ? (
          <Stack direction='row' alignItems={'center'} ml="20px" onClick={nextLink}>
          <Typography sx={{ color: "#fff",
                fontWeight: 600,
                fontSize: "1em",}} color='#fff'>
            Account
          </Typography>
          <IconButton
        size="large"
        color="inherit"
        aria-label="icon"
      >
     { !next ?    <KeyboardArrowDownRoundedIcon fontSize="small" sx={{ color: "#fff" }} /> : <KeyboardArrowUpRoundedIcon fontSize="small" sx={{ color: "#fff" }} />}
      </IconButton>
          </Stack>
        ) : (
          <Box onClick={() => menuClick(10)}>
          <ColorButton>Connect wallet</ColorButton>
        </Box>
        )
      }
      {next && (
       <Stack spacing={2} direction="column" alignItems={"start"} ml='20px'>
       {settings.map((page, index) => (
         <Typography key={index} sx={{
          color: "#fff",
          fontWeight: 300,
          fontSize: "1em",
         }}
         onClick={() => menuClick(page)}>
             {page.name}
         </Typography>
       ))}
     </Stack>)}
    </Box>
  
  );

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        sx={{ border: "none", outline: "none",width:'100% !important','& .MuiDrawer-paper':{width:'100%'} }}
      >
        {list()}
      </SwipeableDrawer>
    </>
  );
}
