
import { createContext, useContext, useState } from "react";
const initialState = {
    itemCtx: null,
    setItemCtx: () => null
}

export const CollectionContext = createContext(initialState);

export const CollectionProvider = ({ children }) => {
  const [itemCtx, setItemCtx] = useState(null);
  return (
    <CollectionContext.Provider
      value={{
        itemCtx, setItemCtx
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};

export const useCollection = () => useContext(CollectionContext);
