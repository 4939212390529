import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { useSpinner } from '../../../contexts/SpinnerContext';



const Spinner = () => {
    const { spinner } = useSpinner();

    return (
        <Backdrop sx={{
            zIndex: 10000,
            color: '#fff',
        }} open={spinner}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default Spinner;