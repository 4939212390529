import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar, Avatar, Box, CardMedia, IconButton, InputBase, Menu,
  MenuItem, Paper, Stack, Toolbar, Tooltip, Typography, useMediaQuery
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { useWeb3React } from "@web3-react/core";
import decode from 'jwt-decode';
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import logo from "../../../../assets/images/header/logo.png";
import { ColorButton } from "../../../../styles/component/button";
import ToggleSideBar from "../../../Drawer";

import { navbarLink } from "../../../../assets/data/navbar";
import { useAuthContext } from "../../../../contexts/AuthContext";
import { useSnackbar } from "../../../../contexts/SnackbarContext";
import { useWallet } from "../../../../contexts/WalletContext";
import { Colors } from "../../../../styles/theme";

const Search = styled(Paper)(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: "10px",
  width: "40%",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    color: theme.palette.common.white,
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "60ch",
    [theme.breakpoints.down("sm")]: {
      width: "9ch",
      "&:focus": {
        width: "12ch",
      },
    },
  },
  "&:placeholder": {
    color: "#808089",
  },
}));
export const Header = () => {
  const { disconnect } = useWallet();
  const {showSnackbar} = useSnackbar()
  const { user, refreshToken } = useAuthContext();
  const { account, active, chainId, deactivate } = useWeb3React();
  const location = useLocation();

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const isTablet = useMediaQuery("(max-width: 1024px)");
  const navigation = useNavigate();
  const settings = [
    {
      id: 1,
      name: "Profile",
      link: "/profile",
    },
    {
      id: 2,
      name: "My Collections",
      link: "/my-collection",
    },
    {
      id: 3,
      name: "Settings",
      link: `/edit-profile/${account}`,
    },
    {
      id: 4,
      name: "Logout",
      link: "/logout",
    },
  ];
  
  useEffect(() => {
    const token = localStorage.getItem('token')
    if(token){
      const decodedToken = decode(token);
      if(decodedToken.exp * 1000 < new Date().getTime()){
        // refreshToken();
        disconnect();
        showSnackbar({message: 'Your session has expired. Please login again', type: 'error'})
        setTimeout(() => {
            window.location.href = "/connect-wallet";
        }, 3000);
      }
    }
  }, [location]);
  const ToggleMenu = () => {
    setIsOpen(true);
  };
  const gotoHomePage = () => {
    navigation("/");
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    navigation(`/search?q=${search}`);
  };

  const menuClick = (menu) => {

    if (menu.id !== 4) {
      if (menu.id === 1) {
        navigation(`/user/${account.toLowerCase()}`);
        handleCloseUserMenu()
      }
      if (menu.id === 2) {
        navigation("/my-collection");
        handleCloseUserMenu()
      }
      if (menu.id === 3) {
        navigation(`/edit-profile/${account}`);
        handleCloseUserMenu()
      }
    } else {
      handleCloseUserMenu()
      disconnect();
      window.location.href = "/connect-wallet";
    }
  };
  return (
    <AppBar
      sx={{
        backgroundColor: "transparent",
        backgroundImage: "none",
        boxShadow: "none",
        position: "relative",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ height: "30px", cursor: "pointer" }} onClick={gotoHomePage}>
          <CardMedia
            sx={{ height: "100%", width: "100%", objectFit: "contain" }}
            component={"img"}
            src={logo}
            alt="logo"
          />
        </Box>

        <Search component="form" color="#fff" onSubmit={handleSubmit}>
          <SearchIconWrapper>
            <SearchIcon sx={{color:'#fff'}}/>
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search by NFTs, collections, and accounts"
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearch}
          />
        </Search>

        <Box
          sx={{
            display: { xs: "none", md: "none", lg: "flex" },
            gap: "20px",
            alignItems: "center",
          }}
        >
          <Stack spacing={3} direction="row">
            {navbarLink.map((page, index) =>
              page.auth ? (
                account &&
                user && (
                  <Link
                    to={page.path}
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      fontWeight: 600,
                      fontSize: "1em",
                    }}
                    key={index}
                  >
                    <Typography fontWeight={500}>{page.name}</Typography>
                  </Link>
                )
              ) : (
                <Link
                  to={page.path}
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: "1em",
                  }}
                  key={index}
                >
                  <Typography>{page.name}</Typography>
                </Link>
              )
            )}
          </Stack>
          {account && user ? (
            <Box sx={{ flexGrow: 0, background: "transparent !important" }}>
              <Box
                sx={{
                  borderRadius: "50%",
                  height: "auto",
                  overflow: "hidden",
                  background: "#f3f3f3",
                  position: "relative",
                  border: "2px solid " + Colors.border,
                }}
              >
                <Tooltip title="Profile Detail">
                  <IconButton  onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt="Remy Sharp"
                      src={
                        user.profilePic ||
                        "https://shibatoken.com/images/ico-red.svg"
                      }
                    />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                sx={{
                  mt: "45px",
                  "& .MuiList-root": {
                    background: "#1E1320",
                    padding: "unset",
                  },
                  "& .MuiPaper-root": {
                    background: "transparent !important",
                  },
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => menuClick(setting)}
                    sx={{
                      "&:hover": {
                        background: "#28102b",
                      },

                      borderTop: "0.5px solid rgba(255, 255, 255, 0.2)",
                    }}
                  >
                    <Typography textAlign="center" fontWeight={500} color="#E5E9F0">
                      {setting.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <Link to="/connect-wallet">
              <ColorButton>Connect wallet</ColorButton>
            </Link>
          )}
        </Box>
        <IconButton
          size="large"
          color="inherit"
          aria-label="menu"
          sx={{
            display: { md: "flex", lg: "none", xl: "none" },
            zIndex: "9999",
          }}
          onClick={ToggleMenu}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
      </Toolbar>
      {isTablet && (
        <ToggleSideBar
          isOpen={isOpen}
          settings={settings}
          account={account}
          user={user}
          setIsOpen={setIsOpen}
        />
      )}
    </AppBar>
  );
};
