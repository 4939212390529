import axios from 'axios';
import jwt_decode from "jwt-decode";
import queryString from 'query-string';


// const baseUrl = 'http://120.72.107.188:3001/api'
const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001/api'

const generateRefreshToken = async () => {
  return " ";
  /**
  try {
      const refreshToken = localStorage.getItem('refreshToken')
      const { address } = JSON.parse(localStorage.getItem('user'))
      const { data } = await axios.post(`${baseUrl}/api/user/refreshtoken`, { refreshToken: refreshToken, address });
      localStorage.setItem('tokens', JSON.stringify(data))
      return data;
  } catch (err) {
      console.log(err);
  }
   */
};

const axiosClient = axios.create({
  baseURL: baseUrl,
  paramsSerializer: params => queryString.stringify({ params })
})

axiosClient.interceptors.request.use(
  async (config) => {
      config.headers["Content-Type"] = 'application/json';
      const accessToken = localStorage.getItem('token') || '';
      let currentDate = new Date();
      if(accessToken) {
          const decodedToken = jwt_decode(accessToken);
          if (decodedToken.exp * 1000 < currentDate.getTime()) {
              const data = await generateRefreshToken();
              config.headers["Authorization"] = "Bearer " + data.accessToken;
              return config
          } else {
              config.headers["Authorization"] = "Bearer " + accessToken
              return config
          }
      }
      return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(response => {
  if (response && response.data) return response.data
  return response
}, err => {
  if (!err.response) {
    return console.log(err)
  }
  throw err.response
})

export default axiosClient